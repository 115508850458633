import Header from 'components/Header'
import Background from 'components/Background'
import Hero from 'components/Hero';
import Icons from 'components/Icons';
import Partner from 'components/Partner';
import Resources from 'components/Resources';
import MobileMenu from 'components/MobileMenu';
import ScrollTriggerView from 'components/ScrollTriggerView';
import { GlobalContextWrapper } from 'store/GlobalContext';

function App() {

  return (
    <GlobalContextWrapper>
      <div className="app">
        <div>
          <Background />
          <ScrollTriggerView id="hero" viewId="hero">
            <Hero />
          </ScrollTriggerView>
          <ScrollTriggerView id="partner" viewId="partner">
            <Partner />
          </ScrollTriggerView>
          <ScrollTriggerView id="icons" viewId="icons">
            <Icons />
          </ScrollTriggerView>
          <ScrollTriggerView id="resources" viewId="resources">
            <Resources />
          </ScrollTriggerView>
        </div>
        <Header />
        <MobileMenu />
      </div>
    </GlobalContextWrapper>
  );
}

export default App;
