import React, {useState} from 'react';

export const GlobalContext = React.createContext(null);

export const GlobalContextWrapper = (props) => {
	
  const [ store, setStore ] = useState({
		view: ''
	});
	
	return (
		<GlobalContext.Provider value={{ store, setStore }}>
			{props.children}
		</GlobalContext.Provider>
	);
}