import React, { useEffect, useRef, useState } from 'react'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Hero.module.scss'
import cx from 'classnames'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg'
import ReactPlayer from 'react-player'
import ScrollLink from 'components/ScrollLink'
import gsap, { ScrollTrigger } from 'gsap/all'
import Disclaimer from 'components/Disclaimer'


const Hero = () => {

  const rootView = useRef(null)

  const [viewActive, setViewActive] = useState(false)

  useEffect(() => {
    if(rootView.current) {

      const timeline = gsap.timeline()
        .fromTo('.gs-hero', {y: '0vh' }, { y: '10vh'})

      ScrollTrigger.create({
        trigger: rootView.current,
        start: "top top+=10",
        end: "bottom top",
        scrub: true,
        animation: timeline,
        onToggle: ({isActive}) => {
          setViewActive(isActive)
        }
      });

      gsap.timeline({ delay: .3 })
        .fromTo('.gs-hero-item' , {
          opacity:0,
          y: '-3vh',
        },{
          opacity: 1,
          y: 0,
          stagger: .1
        })
      
    }
  }, [rootView])

  return (
    <div ref={rootView} className={cx(layout.slide, styles.hero)}>
      <div className={styles.videoContainer}>
        <ReactPlayer
          url="video/header-reel.mp4"
          volume={0}
          muted={false}
          loop={true}
          playing={viewActive}
          width='100%'
          height='100%'
          className={styles.videoPlayer}
          />
        <div className={styles.videoOverlay}></div>
      </div>
      <div className={cx('t-center', styles.heroContent)}>
        <div className='gs-hero'>
          <h1 className='gs-hero-item'>Production Health and Safety</h1>
          <div className={cx('gs-hero-item', styles.seperator)} />
          <div className={cx('gs-hero-item', styles.textWrapper)}>
            <p className='featured'>While working with Netflix, our production partners and their teams should always consider the health and safety risks in their daily work.</p>
          </div>
          <div className={cx('gs-hero-item', styles.disclaimerWrapper)}>
            <Disclaimer />
          </div>
          <div className={'gs-hero-item'}>
            <ScrollLink speed={500} id="partner">
              <div className='bounce'>
                <ArrowDown />
              </div>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero