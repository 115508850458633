import React from 'react'
import gsap from 'gsap/all'
import styles from './ScrollLink.module.scss'
import cx from 'classnames'

const ScrollLink = ({id, offset = 0, click, className, children, ...props}) => {

  const handleClick = (e) => {
    e.preventDefault()
    
    const link = `#${id}` //e.currentTarget.getAttribute('href')
    const element = document.querySelector(link)
    
    gsap.to(window, {
      duration: 1.4,
      ease: 'quart.out',
      scrollTo: {
        y:element.offsetTop - offset,
        overwrite: "auto",
      }
    })
    
    if(click) {
      click()
    }
  }

  return (
   <a className={cx(styles.link, className)} onClick={handleClick} {...props}>
    {children}
   </a>
  )
}

export default ScrollLink