import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.scss';
import App from './App';

import gsap, { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
