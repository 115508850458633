import React from 'react'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Disclaimer.module.scss'
import cx from 'classnames'

const Disclaimer = () => {

  return (
    <div className={cx(layout.slide, styles.root)}>
      <div className={cx(layout.container, styles.container)}>
        <div className={cx('gs-disclaimer', styles.box)}>
          <p><strong>Important</strong>: These resources offer tips and tools for tackling key health and safety challenges in production. They don't cover local laws or specific requirements, so be sure to check those when planning. For more details, reach out to your Netflix Production Partner.</p>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer