import React, { useEffect, useRef, useState } from 'react'
import gsap, { ScrollTrigger } from 'gsap/all'

import layout from 'styles/modules/Layout.module.scss'
import styles from './Partner.module.scss'
import Layout from 'styles/modules/Layout.module.scss'

import Poster1Image from 'assets/img/video-poster.jpg'
import Poster2Image from 'assets/img/video-poster2.jpg'
import Poster3Image from 'assets/img/video-poster3.jpg'

import { ReactComponent as PlayIcon } from 'assets/svg/play.svg'

import cx from 'classnames'
import ReactPlayer from 'react-player'

const Partner = () => {

  const rootView = useRef(null)
  const [viewActive, setViewActive] = useState(false)
  
  useEffect(() => {
    if(rootView.current) {
      const timeline = gsap.timeline({})
      .fromTo('.gs-vid1', {
        y: "-25%"
      },{
        y: "15%"
      }, 0)
      .fromTo('.gs-vid2', {
        y: "-80%"
      },{
        y: "45%"
      }, 0)
      .fromTo('.gs-vid3', {
        y: "-130%"
      },{
        y: "80%"
      }, 0)

      ScrollTrigger.create({
        trigger: rootView.current,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        animation: timeline,
        onToggle: ({isActive}) => {
          setViewActive(isActive)
        }
      });

    }
  },[rootView])
  
  return (
    <div ref={rootView} className={cx(layout.slide, styles.partner)}>
      <div className={Layout.container}>
        <div className={styles.grid}>
          <div className={styles.item}>
            <div className={styles.itemInner}>
              <h2>Storytelling Safely</h2>
              <p>
              One of our 4 key principles is people over process. Safety should empower us, not hold us back. When we get it right, safety sparks curiosity, encourages open conversations, and drives us to ask more questions and collaborate on tough challenges. With this mindset productions become great places to work, where creativity thrives. Together, we can raise the bar on safety in the entertainment industry.
              </p>
              <p>Play the video to find out more about what this means to our Partner productions.</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <div className={styles.imageWrapper}>
                <img className={cx('gs-vid3',styles.videoImage, styles.imageTwo)} src={Poster3Image} alt="PosterImage" />
                <img className={cx('gs-vid2',styles.videoImage, styles.imageOne)} src={Poster2Image} alt="PosterImage" />
                <div className={cx('gs-vid1', styles.videoPlayer)}>
                  {viewActive && <ReactPlayer
                    url="video/partners.mp4"
                    width='100%'
                    height='100%'
                    controls={true}
                    light={Poster1Image}
                    playing={true}
                    playIcon={<div className={styles.playIcon}><PlayIcon /></div>}
                    className={cx('gs-vid1', styles.videoPlayer)}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partner