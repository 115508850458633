import React from 'react'

import styles from './Footer.module.scss'

import { ReactComponent as Logo } from 'assets/svg/logo.svg'
import { TERMS } from 'store/Constants'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <div>
          <Logo />
        </div>
        <div>
          <p>All uses of the Netflix materials are subject to the <a href={TERMS} rel="noreferrer" target="_blank">Terms&nbsp;and&nbsp;Conditions</a>.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer