import React, { useContext, useState } from 'react'

import styles from './Background.module.scss'
import cx from 'classnames'
import { GlobalContext } from 'store/GlobalContext'
import { useEffect } from 'react'

const indexFromState = (state => {
  switch(state) {
    case 'icons':
      return 2
    case 'resources':
      return 3
    default:
      return 1
  }
})

const Icons = () => {

  const [section, setSection] = useState(0)

  const {store} = useContext(GlobalContext);
  useEffect(() => {
    const index = indexFromState(store.view)
    setSection(index)
  }, [store.view])

  return (
    <div className={cx(styles.gradients)}>
      <div style={{opacity: section === 1 ? 1 : 0}} className={cx(styles.gradient, styles.right, styles.red)}></div>
      <div style={{opacity: section !== 1 ? 1 : 0}} className={cx(styles.gradient, styles.right, styles.blue)}></div>
      <div style={{opacity: section === 3 ? 1 : 0}} className={cx(styles.gradient, styles.left, styles.purple)}></div>
      <div style={{opacity: section !== 3 ? 1 : 0}} className={cx(styles.gradient, styles.left, styles.orange)}></div>
    </div>
  )
}

export default Icons