export const HOME = "https://www.safersets.com"
export const GLOBAL_EXPECTATIONS = "https://drive.google.com/file/d/1Sv2AkJGgFUMrZSR0pACmpd9EGBDjiBwj/view?usp=share_link"
export const DOWNLOAD_CENTER = "https://sites.google.com/netflix.com/netflixemeaprodhealthandsafety/"
export const INDIVIDUAL_ICONS = "https://drive.google.com/drive/folders/19Tv91dR6-Os581K_R2hDD_x4mMeWJH1s?usp=share_link"
export const ICONS = "https://drive.google.com/file/d/1zXyaUozZZnNdzMZQdR2bipn1BrCc0VkC/view?usp=sharing"
export const ICON_GROUPS = "https://drive.google.com/drive/folders/1CrB6UGbj3oUcl2REmgSmTC8riOu9WSJ5?usp=share_link"
export const ICON_TEXT = "https://drive.google.com/file/d/1qqaFeImfCM6prgXgekSCHM-FVZIJWmrP/view?usp=sharing"
export const TOOLKIT = "https://drive.google.com/file/d/1tNWJ_b5Ei09EazQyWOZ_Uo2TCaMapclv/view?usp=sharing"
export const TERMS = "https://brand.netflix.com/#/terms"

const LINKS = {
  HOME,
  GLOBAL_EXPECTATIONS,
  ICONS,
  DOWNLOAD_CENTER,
  INDIVIDUAL_ICONS,
  ICON_GROUPS,
  ICON_TEXT,
  TOOLKIT,
  TERMS,
}

export default LINKS