import React, { useContext, useRef, useEffect} from 'react'

import { ScrollTrigger } from 'gsap/all'
import { GlobalContext } from 'store/GlobalContext';

const ScrollTriggerView = ({id, viewId, children}) => {

  const rootView = useRef(null)
  const {setStore} = useContext(GlobalContext);

  useEffect(() => {
    if(rootView.current) {
      ScrollTrigger.create({
        id: viewId,
        trigger: rootView.current,
        start: "top center",
        end: "bottom center",
        onToggle: ({direction, isActive}) => {
          if(isActive) {
            setStore({view: viewId})
          }
        }
      });
    }
    // eslint-disable-next-line
  },[rootView])

  return (
   <div ref={rootView} id={id}>
    {children}
   </div>
  )
}

export default ScrollTriggerView